<template>
  <div class="page inPage buttonPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Button</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="按钮类型" inset>
        <lee-button type="primary">主要按钮</lee-button>
        <lee-button type="success">成功按钮</lee-button>
        <lee-button type="default">默认按钮</lee-button>
        <lee-button type="warning">警告按钮</lee-button>
        <lee-button type="danger">危险按钮</lee-button>
      </lee-cell-group>
      <lee-cell-group title="朴素按钮" inset>
        <lee-button type="primary" plain @click="ps">朴素按钮</lee-button>
        <lee-button type="success" plain>朴素按钮</lee-button>
        <lee-button type="default" plain>朴素按钮</lee-button>
        <lee-button type="warning" plain>朴素按钮</lee-button>
        <lee-button type="danger" plain>朴素按钮</lee-button>
      </lee-cell-group>
      <lee-cell-group title="禁用状态" inset>
        <lee-button type="primary" disabled @click="jy">禁用状态</lee-button>
        <lee-button type="success" disabled>禁用状态</lee-button>
      </lee-cell-group>
      <lee-cell-group title="加载状态" inset>
        <lee-button type="primary" loading />
        <lee-button type="primary" loading loading-type="spinner" />
        <lee-button type="success" loading >加载中...</lee-button>
      </lee-cell-group>
      <lee-cell-group title="按钮形状" inset>
        <lee-button type="primary" icon="add"/>
        <lee-button type="primary" icon="add">按钮</lee-button>
        <lee-button icon="https://fastly.jsdelivr.net/npm/@vant/assets/user-active.png" plain type="primary"
        >
          按钮
        </lee-button>
      </lee-cell-group>
      <lee-cell-group title="图标按钮" inset>
        <lee-button square type="primary">方形按钮</lee-button>
        <lee-button round type="success">圆形按钮</lee-button>
      </lee-cell-group>
      <lee-cell-group title="按钮尺寸" inset>
        <lee-button type="primary" size="large">大号按钮</lee-button>
        <lee-button type="primary" size="normal">普通按钮</lee-button>
        <lee-button type="primary" size="small">小号按钮</lee-button>
        <lee-button type="primary" size="mini">迷你按钮</lee-button>
      </lee-cell-group>
      <lee-cell-group title="页面导航" inset>
        <lee-button type="primary" url="http://v1.leeao82.com/">URL 跳转</lee-button>
        <lee-button type="primary" to="/">路由跳转</lee-button>
      </lee-cell-group>
      <lee-cell-group title="自定义颜色" inset>
        <lee-button color="#7232dd" text-color="#fff">单色按钮</lee-button>
        <lee-button color="#7232dd" plain>单色按钮</lee-button>
        <lee-button color="linear-gradient(to right, #ff6034, #ee0a24)"  text-color="#fff">渐变色按钮</lee-button>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
//
const ps = () => {
    console.log("朴素")
}
const jy = () => {
    console.log("禁用")
}
</script>
<style>
.buttonPage .inset{background: var(--lee-white);
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
}
.buttonPage .lee-button {
  margin-left: 16px;
  margin-bottom: 16px;
}

.buttonPage .lee-button-large {
  margin-left: 0;
}

</style>
